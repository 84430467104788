import {ISchoolsRepository} from './ISchoolRepository';

import {School, SchoolsConnection} from '../../graphql/API';
import {school, schools} from './SchoolQueries';
import {generateClient,GraphQLResult} from "aws-amplify/api";

const client = generateClient();
export class SchoolRepository implements ISchoolsRepository {
  async getSchools(
    nextToken: string,
    searchValue?: string,
  ): Promise<SchoolsConnection> {
    try {
      const filter = {name: {contains: searchValue}};

      const response: any = await client.graphql({
        query: schools,
        variables: {
          nextToken,
          filter,
          ...(searchValue?.length > 0 ? null : {scannedCount: 101}),
        },
      });

      return response.data?.schools;
    } catch (e) {
      console.log('response Error->', e);
    }
  }

  async getSchoolById(schoolId: string): Promise<School> {
    try {
      const response: any = await client.graphql({
        query: school,
        variables: {
          id: schoolId,
        },
      });
      return response.data?.school;
    } catch (error) {
      console.log('getSchoolById Error: ', error);
    }
  }
}
