import {Alert, Button, Layout} from 'antd';
import {useEffect} from 'react';
import {Switch, useHistory, useLocation} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Route} from 'react-router-dom';

import SignIn from '../modules/SignIn';
import {reducersState} from '../redux/reducers';
import {SiderComponent} from '../components/Sider';

import {Content} from 'antd/lib/layout/layout';
import 'react-toastify/dist/ReactToastify.css';
import {Home} from '../modules/Home';
import {StudentReportPage} from '../modules/Report/StudentReport';
import {doSignInWithPartner, isLoadingRequest} from '../redux/actions/user';
import StudentsArrangementPage from '../modules/Report/StudentReport/StudentsArrangementPage';
import {AdminPage} from '../modules/Admin';

export const Routers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location: {
    state: any;
    hash: any;
    search: string;
    pathname: string;
  } = useLocation();

  const isLoggedIn = useSelector(
    (state: reducersState) => state.user.isLoggedIn,
  );

  const school = useSelector((state: reducersState) => state.user.school);

  const isLoggedInAsPartner = useSelector(
    (state: any) => state.user.partnerUser,
  );

  const error = useSelector((state: reducersState) => state.user.error);

  useEffect(() => {
    if (
      location?.search?.includes('partner:') &&
      location?.search?.includes('token:') &&
      !isLoggedInAsPartner
    ) {
      const arrState = location.search.split('?');
      const partnerFounded = arrState.find((value: string) =>
        value.startsWith('partner:'),
      );

      if (arrState && partnerFounded) {
        const partnerId: any = partnerFounded?.split(':')[1];
        console.log('partnerFounded', partnerId);

        dispatch(
          doSignInWithPartner(
            {
              url: location.search,
            },
            partnerId,
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn, isLoggedInAsPartner]);

  useEffect(() => {
    if ((isLoggedIn || isLoggedInAsPartner) && school) {
      history.push(`/school/${school?.id}`);
    }
  }, [school, isLoggedInAsPartner, isLoggedIn]);

  return (
    <div>
      <div style={{marginTop: 50, position: 'absolute'}}>
        {error && (
          <Alert
            description={error.message}
            type="error"
            style={{
              fontWeight: 'bold',
              zIndex: 5,
              width: '400px',
              // height: '200px',
              // display: 'block',
            }}
            message="Error Occurs"
            showIcon
            action={
              <Button
                size="small"
                danger
                onClick={() => {
                  dispatch(isLoadingRequest(false, null));
                }}>
                Dismiss
              </Button>
            }
          />
        )}
      </div>
      {!isLoggedIn ? (
        <SignIn />
      ) : (
        <Layout>
          <Content style={{backgroundColor: '#FFFFFF'}}>
            <SiderComponent />
            <Switch>
              {/** Student */}
              <Route
                exact
                path="/student/:studentId"
                component={StudentReportPage}
              />
              <Route
                exact
                path="/students-arrangement"
                component={StudentsArrangementPage}
              />
              {/** Schools */}
              <Route exact path="/schools" component={AdminPage} />
              <Route path="/school/:schoolId" component={Home} />
            </Switch>
          </Content>
        </Layout>
      )}
    </div>
  );
};
