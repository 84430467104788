import {
  GET_USER,
  NO_USER_FOUND,
  IS_LOADING_REQUEST,
  SIGN_IN_SUCCESS,
  SET_CONVERSATIONS,
  SET_SUBSCRIBED_ON_CONVERSATION,
  SIGN_IN__WITH_PARTNER_SUCCESS,
  IS_LOADING_REQUEST_LOGIN,
  SET_SCHOOL,
  SET_TEACHER,
  SET_CLASSES,
} from '../actions/types';
import { SignInOutput } from 'aws-amplify/auth';

import {Classschool,
    PikaDoPartners,
    School,
    Teacher,
    User,
    Userconversation,} from '../../graphql/API';

export type UserState = {
  user: SignInOutput | null;
  currentUser: User | null;
  partnerUser: PartnerUser | null;
  isLoggedIn: boolean;
  isLoadingRequest: boolean;
  isAdmin: boolean;
  error: null | any;
  school: School;
  teacher: Teacher;
  classes: Array<Classschool>;

  conversations: Array<Userconversation>;
  subscribedConversationIds: Array<string>;
};

export type PartnerUser = {
  partnerAppName: PikaDoPartners | null;
  sharedTeacherId: string | null;
  isAuthenticated: boolean;
  cognitoUserPartnerId: string;
  token?: string | null;
};

export const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  currentUser: null,
  partnerUser: null,
  isLoadingRequest: false,
  error: null,
  conversations: [],
  subscribedConversationIds: [],
  isAdmin: false,
  school: null,
  teacher: null,
  classes: [],
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };

    case GET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case NO_USER_FOUND:
      return {
        ...state,
        user: null,
        error: null,
        isLoggedIn: false,
        partnerUser: null,
      };

    case IS_LOADING_REQUEST: {
      return {
        ...state,
        isLoadingRequest: action.payload.isLoading,
        error: action.payload.error,
      };
    }

    case SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.payload,
      };
    }

    case SET_SUBSCRIBED_ON_CONVERSATION: {
      return {
        ...state,
        subscribedConversationIds: action.payload,
      };
    }

    case IS_LOADING_REQUEST_LOGIN:
      return {
        ...state,
        isLoadingRequest: action?.payload?.isLoading,
        error: action?.payload?.error || null,
      };

    case SIGN_IN__WITH_PARTNER_SUCCESS:
      return {
        ...state,
        partnerUser: action.payload,
        isLoading: false,
        isLoggedIn: true,
        error: null,
      };

    case SET_SCHOOL:
      return {
        ...state,
        school: action.payload,
      };

    case SET_TEACHER:
      return {
        ...state,
        teacher: action.payload,
      };

    case SET_CLASSES:
      return {
        ...state,
        classes: action.payload,
      };

    default:
      return state;
  }
};

export default user;
