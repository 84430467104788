import {AuthRepository} from '../../lib/repositories/AuthRepository';
import {
  GET_USER,
  IS_LOADING_REQUEST,
  NO_USER_FOUND,
  SIGN_IN_SUCCESS,
  USER_IS_LOADING,
  SIGN_IN__WITH_PARTNER_SUCCESS,
  IS_LOADING_REQUEST_LOGIN,
  SET_SCHOOL,
  SET_TEACHER,
  SET_CLASSES,
  SET_SELECTED_STUDENT,
} from './types';

import { AuthenticatorResponse,
    Classschool,
    PikaDoPartners,
    School,
    Teacher,
    User,} from '../../graphql/API';
import {persistor, store} from '../store';
import type {PartnerUser} from '../reducers/user';
import {Amplify} from 'aws-amplify';
import {enable} from 'aws-amplify/analytics';
import {SignInOutput} from 'aws-amplify/auth';
import awsmobile from '../../aws-exports';
import {notification} from 'antd';
import {decodeToken} from 'react-jwt';
store.subscribe(getPartnerAuthToken);

function selectPartnerUser(state: any): PartnerUser {
  return state.user.partnerUser;
}

export function getPartnerAuthToken(): string | undefined {
  let partnerUsr: PartnerUser = selectPartnerUser(store.getState());
  const authToken: string =
    'partner_auth-' + partnerUsr?.token ?? partnerUsr?.partnerAppName;

  return partnerUsr?.isAuthenticated ? authToken : undefined;
}

export function getPartnerCognitoId(): string {
  let partnerUsr: PartnerUser = selectPartnerUser(store.getState());
  const partnerCognitoId: string = partnerUsr?.cognitoUserPartnerId ?? '';

  return partnerUsr?.isAuthenticated ? partnerCognitoId : '';
}

export const setSignInPartner =
  (partUser: PartnerUser) => async (dispatch: any) => {
    console.log('from actions ->', partUser);
    try {
      dispatch({type: SIGN_IN__WITH_PARTNER_SUCCESS, payload: partUser});
    } catch (error) {
      console.log('SIGN_IN_WITH_PARTNER_SUCCESS error: ', error);
    }
  };

export const isLoadingRequest = (isLoading: boolean, error: any = null) => {
  return {
    type: IS_LOADING_REQUEST,
    payload: {isLoading, error},
  };
};

const authRepo = new AuthRepository();

export const getUser = () => async (dispatch: any, getState: any) => {
  console.log('ENTER_GET_USER');
  try {
    let user: User = await authRepo.getCurrentUser();

    if (user) {
      dispatch({type: GET_USER, payload: user});
      let school = user.teacher?.school ?? user.school;
      let teacher = user.teacher;
      let classes = user.teacher?.classesschool ?? user.school?.classesschool;
      await dispatch(setSchool(school, teacher, classes));
    } else {
      console.log('ERROR_NO_USER_FOUND');
      dispatch(isLoadingRequest(false));
      dispatch({type: NO_USER_FOUND});
    }
  } catch (error) {
    dispatch(isLoadingRequest(false, error));
    dispatch({type: 'RESET_USER'});
    console.log('ERROR_GET_USER', error);
    // dispatch(doSignOut());
  }
};

export const setSchool =
  (school: School, teacher: Teacher, classes: Array<Classschool>) =>
  async (dispatch: any) => {
    try {
      dispatch({type: SET_SCHOOL, payload: school});
      dispatch({type: SET_TEACHER, payload: teacher});
      dispatch({type: SET_CLASSES, payload: classes ?? []});
    } catch (error) {
      console.log('SetSchool action error: ', error);
    }
  };

export const logIn =
  (email: string, password: string) => async (dispatch, getState) => {
    configAmplifyCognitoRequest();
    dispatch(isLoadingRequest(true));

    try {
      let response: SignInOutput = await authRepo.signIn(email, password);
      console.log('RESSS', response);
      await dispatch(getUser());
      dispatch({type: SIGN_IN_SUCCESS, payload: response});

      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      console.log('SIGN_IN_ERROR', error.message);
      dispatch(isLoadingRequest(false, error));
    }
  };

// export const set = (userConversations: Array<null>) => {
//   return {type: SET_CONVERSATIONS, payload: userConversations};
// };

export const isLoadingLogInRequest = (
  isLoading: boolean,
  error: any = null,
) => {
  return {
    type: IS_LOADING_REQUEST_LOGIN,
    payload: {isLoading, error},
  };
};

export const isLoadingUser = (isLoading: boolean) => {
  return {
    type: USER_IS_LOADING,
    payload: {isLoading},
  };
};

export const doSignInWithPartner =
  (navState: any, partnerAppName: PikaDoPartners) =>
  async (dispatch, getState) => {
    try {
      dispatch({type: 'RESET_USER'});

      console.log('navState.url->', navState.url);

      const arrState = navState.url.split('?');
      console.log('arrState>1', arrState);

      const tokenFounded = arrState.find((value: string) =>
        value.startsWith('token:'),
      );

      console.log('tokenFounded', tokenFounded);

      const token = tokenFounded?.split(':')[1];

      console.log('token', token);

      const myDecodedToken: any = decodeToken(token);
      console.log('myDecodedToken', myDecodedToken.teacher_id);
      let partnerId = myDecodedToken?.teacher_id ?? myDecodedToken?.school_id;

      if (arrState && partnerId) {
        dispatch(isLoadingLogInRequest(true));

        // const teacherId = teacherIdFounded?.split(':')[1];

        configAmplifyLambdaRequest();

        const resp: AuthenticatorResponse = await authRepo.validatePartnerAuth(
          partnerAppName,
          partnerId,
        );

        const myDecodedTokenAuth: any = decodeToken(resp.token);

        const partUser: PartnerUser = {
          partnerAppName,
          sharedTeacherId: partnerId,
          isAuthenticated: true,
          cognitoUserPartnerId: myDecodedTokenAuth?.user?.cognitoUserId,
          token: resp.token,
        };

        dispatch(setSignInPartner(partUser));
        configAmplifyLambdaRequest();

        await dispatch(getUser());
      }
      console.log(arrState);

      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      dispatch(doSignOut());
      console.log('SIGN_IN_ERROR2', error);
      setTimeout(() => {
        window.location.href = '/';
      }, 3200);

      dispatch(isLoadingLogInRequest(false, error));

      if (error?.errors?.length !== 0) {
        notification.error({
          placement: 'top',
          duration: 10,
          message: error?.errors[0]?.message,
        });
      } else {
        notification.error({
          placement: 'top',
          duration: 10,
          message: 'Error in logging in',
        });
      }
    }
  };

export function configAmplifyLambdaRequest() {
  // Amplify.configure({
  //   ...awsmobile,
  //   //@ts-ignore
  //   auth: {type: 'CUSTOM_AUTH', jwtToken: getPartnerAuthToken()},
  //   PushNotification: {
  //     requestIOSPermissions: true,
  //   },
  //   Analytics: {
  //     disabled: true,
  //   },
  //   aws_appsync_authenticationType: 'AWS_LAMBDA',
  // });
  Amplify.configure({
    ...awsmobile,
    auth: {
      //@ts-ignore
      type: 'CUSTOM_AUTH',
      jwtToken: getPartnerAuthToken(),
    },
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  });
  enable();
}

export function configAmplifyCognitoRequest() {
  Amplify.configure({
    ...awsmobile,
    // PushNotification: {
    //   requestIOSPermissions: true,
    // },
    // Analytics: {
    //   disabled: true,
    // },
  });
  enable();
}

export const doSignOut = () => async (dispatch: any) => {
  try {
    dispatch({type: 'SIGN_OUT'});
    await authRepo.signOut();
    dispatch({type: 'SIGN_OUT_SUCCESS'});
    dispatch({type: 'RESET_USER'});
  } catch (error) {
    dispatch({type: 'RESET_USER'});
  }
};
