export const schools = /* GraphQL */ `
  query Schools(
    $filter: SchoolsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    schools(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        nameArabic
        name
      }
      nextToken
      scannedCount
    }
  }
`;

export const school = /* GraphQL */ `
  query School($id: String!) {
    school(id: $id) {
      id
      createdAt
      promo {
        id
        type
        thumbnailUri
        fileUri
      }
      updatedAt
      classesschool {
        name
        statistic {
          id
          averageScore
        }
        level {
          id
          number
        }
        id
        students {
          id
          age
          user {
            id
            country
          }
          statistic {
            id
            averageScore
          }
          name
        }
      }
      nameArabic
      name
    }
  }
`;
