import {ISchoolService} from './ISchoolService';
import {SchoolRepository} from './SchoolRepository';
import {School, SchoolsConnection} from '../../graphql/API';

export class SchoolService implements ISchoolService {
  schoolRepository: SchoolRepository;
  constructor() {
    this.schoolRepository = new SchoolRepository();
  }

  async getSchools(
    nextToken: string,
    searchValue?: string,
  ): Promise<SchoolsConnection> {
    return await this.schoolRepository.getSchools(nextToken, searchValue);
  }

  async getSchoolById(schoolId: string): Promise<School> {
    return await this.schoolRepository.getSchoolById(schoolId);
  }
}
