import {useEffect, useState} from 'react';
import {Row, Spin, Table} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import Title from 'antd/es/typography/Title';
import Text from 'antd/lib/typography/Text';

import {SchoolService} from './SchoolService';
import {SchoolsConnection} from '../../graphql/API';

const schoolService = new SchoolService();

export const SchoolsTable = () => {
  const [schools, setSchools] = useState<SchoolsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [current, setCurrent] = useState(1);
  const [maxNumReachedPages, setMaxNumReachedPages] = useState(1);

  useEffect(() => {
    schoolService
      .getSchools(schools?.nextToken)
      .then((resp: SchoolsConnection) => {
        const currentItems = schools?.items ?? [];
        //console.log('discountCodes', resp);

        setSchools({
          ...schools,
          ...resp,
          items: [...currentItems, ...resp.items],
        });

        setIsLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        console.log('getSchools Error: ', error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: any = [
    {
      title: 'Name English',
      dataIndex: 'name',
      render: (name: string, school: any) => {
        //console.log('school', school);
        return <Link to={`school/${school.id}`}> {name}</Link>;
      },
    },
    {
      title: 'Name Arabic',
      dataIndex: 'nameArabic',
      render: (name: string) => {
        return (
          <Text
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
            }}>
            {name}
          </Text>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: (createdAt: string) => {
        return (
          <p
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
            }}>
            {moment(createdAt).format('YYYY-MM-DD')}
          </p>
        );
      },
    },
    {
      title: 'id',
      dataIndex: 'id',
      render: (id: string) => {
        return (
          <p
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
            }}>
            {id}
          </p>
        );
      },
    },
  ];

  const onChangeLogic = ({current}, size) => {
    //console.log('CURRRRR_sizeChangeLogic', current);

    if (current >= maxNumReachedPages) {
      setCurrent(current);
      setMaxNumReachedPages(current);
    }
  };

  return (
    <>
      <Row
        style={{
          margin: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}></Row>
      {!isError && !isLoading && (
        <Table
          pagination={{pageSizeOptions: ['100'], pageSize: 100}}
          current={current}
          defaultPageSize={100}
          // onShowSizeChange={sizeChangeLogic}
          onChange={onChangeLogic}
          // showSizeChanger={true}
          columns={columns}
          dataSource={schools?.items}
          rowKey="id"
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
