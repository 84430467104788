import { classesschool } from './../../graphql/queries';
import {IStatisticRepository} from './IStatisticRepository';
import {generateClient,GraphQLResult} from "aws-amplify/api";
import {generateClassFullReport} from '../../graphql/mutations';
import {fullReport, minimalStudent, studentsArrangement, studentsTable} from './CustomQueries';
import {getPartnerAuthToken} from '../../redux/actions/user';

const client = generateClient();
export class StatisticRepository implements IStatisticRepository {
  constructor() {}

  async generateClassFullReport(classschoolId: string): Promise<any> {
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: generateClassFullReport,
      variables: {
        classschoolId: classschoolId,
      },
      authToken: getPartnerAuthToken()
    })
    console.log('response from generateClassFullReport->', response);
    return response.data.generateClassFullReport;
  }

  async getFullReport(statisticId: string): Promise<any> {
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: fullReport,
      variables: {
        id: statisticId,
      },
      authToken: getPartnerAuthToken()
    });
    console.log('response->', response);
    return response.data.statistic;
  }

  async getStudentById(studentId: string): Promise<any> {
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: minimalStudent,
      variables: {
        id: studentId,
      },
      authToken: getPartnerAuthToken()
    });
    console.log('response->', response);
    return response.data.student;
  }
  async getStudentsBySchoolId(
    schoolId: string,
    classId: string,
  ): Promise<any> {    
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: studentsTable,
      variables: {
        filter:{
            schoolId: {
              eq: schoolId
            },
            classschoolId: {
              eq: classId
            }
          },
      },
      authToken: getPartnerAuthToken()
    });
    console.log('response studentsss->', response);
    return response.data?.students;
  }

/**
 * Retrieves a list of students belonging to a specific class within a specific school.
 *
 * This function makes a GraphQL query to fetch the school's class information
 * and filters the results to find the students in the specified class.
 *
 * @param {string} schoolId - The unique identifier of the school.
 * @param {string} classId - The unique identifier of the class.
 * @returns {Promise<any>} A promise that resolves to an array of students in the specified class,
 * or undefined if no matching class is found.
 *
 * @throws {Error} Throws an error if the GraphQL query fails or if the response structure is unexpected.
 */
  async getStudentsBySchoolIdAndClassId(
    schoolId: string,
    classId: string,
  ): Promise<any> {    
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: studentsArrangement,
      variables: {
              id: schoolId
      },
      authToken: getPartnerAuthToken()
    });
    return response?.data?.school?.classesschool.filter((classSchool:any)=> classSchool.id ==classId )[0].students;
  }
}
